import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import One from '../assets/offImages/baby_touch_1.1.7.png';
import Two from '../assets/offImages/front_officsi_1.1.5.png';
import Three from '../assets/offImages/kiosk_official_2.png';
import Four from '../assets/offImages/kiosk_official_front_1.1.4.png';
import Five  from '../assets/offImages/migak_baby_touch_official_1.1.6.png';
import Six from '../assets/offImages/migak_touching_1.1.7.png';
import Seven from '../assets/offImages/touch_1.1.4.png';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const Checkout = (props) => {
    const [showMenu, setShowMenu] = useState(false);
    const triggerRef = useRef(null); // This ref is for our small div trigger

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    setShowMenu(true);
                } else {
                    setShowMenu(false);
                }
            },
            {
                threshold: 0.1,
            }
        );

        if (triggerRef.current) {
            observer.observe(triggerRef.current);
        }

        return () => {
            if (triggerRef.current) {
                observer.unobserve(triggerRef.current);
            }
        };
    }, []);

    return (
        <>
            <div ref={triggerRef} style={{ height: '5px', marginTop: 40}}></div>

            <h1 className="poppins-font">Let's talk about our <span style={{ background: 'orange' }}>affordable</span> pricing</h1>
            <br />

            <Grid mb={10} container spacing={4} direction="row" justifyContent="center" alignItems="center">
            <Grid item md={4}>
            <Card sx={{ borderRadius: 5, minWidth: 275,minHeight: 300, background:'rgba(255,255,255,0.4)', }}>
            <CardContent>
              <h1  className="poppins-font">
                $0/mo. <span style={{float: 'right', fontWeight: 400}}>Option A</span>
              </h1>
              <h5 style={{ borderBottom: '3px solid orange', paddingBottom: 10}} className="poppins-font" variant="h5" component="div">
                For high volume of sales
              </h5 >

              <p variant="body2" className="poppins-font">
                - 3,000 minimum transactions per month required*<br /> <small style={{marginLeft: 25, color: 'gray'}}>*$150/mo if not met</small>
                <br />

                - $0 transaction fee if passed onto customers* <br /> <small style={{marginLeft: 25, color: 'gray'}}>*2.7% + 30 cents to customers or 2.7% + 25 cents if you pay</small>

                <br />
                - No contract
                <br />
                - $1,000 down due at checkout
              </p>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
            </Grid>
            <Grid item md={4}>
            <Card sx={{ borderRadius: 5,minWidth: 275, minHeight: 300, background:'rgba(255,255,255,0.4)', }}>
            <CardContent>
              <div className="poppins-font" style={{textAlign: 'center'}}><span style={{background: 'orange', padding: 4, borderRadius: 3}}>Most Popular Plan</span></div>
              <h1 className="poppins-font">
                $75/mo. <span style={{float: 'right', fontWeight: 400}}>Option B</span>
              </h1>
              <h5 style={{ borderBottom: '3px solid orange', paddingBottom: 10}} className="poppins-font" variant="h5" component="div">
                For low to moderate volume of sales
              </h5>

              <p variant="body2" className="poppins-font">
                - 900 minimum transactions per month required*<br /> <small style={{marginLeft: 25, color: 'gray'}}>*$150/mo if not met</small>
                <br />

                - $0 transaction fee if passed onto customers* <br /> <small style={{marginLeft: 25, color: 'gray'}}>*2.7% + 35 cents to customers or 2.7% + 25 cents if you pay</small>

                <br />
                - No contract
                <br />
                - $500 down due at checkout
              </p>
            </CardContent>
            <CardActions>
            </CardActions>
          </Card>
            </Grid>
            <Grid item md={4}>
            <Card sx={{ borderRadius: 5, minHeight: 300, background:'rgba(255,255,255,0.4)', minWidth: 275 }}>
            <CardContent>
              <h1 className="poppins-font">
                $150/mo. <span style={{float: 'right', fontWeight: 400}}>Option C</span>
              </h1>
              <h5 style={{ borderBottom: '3px solid orange', paddingBottom: 10}} className="poppins-font" variant="h5" component="div">
                For moderate to high volume of sales
              </h5>

              <p variant="body2" className="poppins-font">
                - no minimum transactions
                <br />

                - $0 transaction fee if passed onto customers* <br /> <small style={{marginLeft: 25, color: 'gray'}}>*2.7% + 35 cents to customers or 2.7% + 25 cents if you pay</small>

                <br />
                - 1-year contract
                <br />
                - $0 down due at checkout
              </p>
            </CardContent>
            <CardActions>
            </CardActions>
            </Card>
            </Grid>
            </Grid>
            <Grid container>
                <Grid item xs>
                    <ImageList sx={{ width: '100%' }} variant="woven" cols={3} gap={8}>
                        {itemData.map((item) => (
                            <ImageListItem key={item.img}>
                                <img
                                    style={{ borderRadius: 5 }}
                                    srcSet={`${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item.img}?w=161&fit=crop&auto=format`}
                                    alt={item.title}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Grid>
            </Grid>


        </>
    );
}

const itemData = [
    {
        img: One,
        title: 'Bed',
    },
    {
        img: Two,
        title: 'Bed',
    },
    {
        img: Three,
        title: 'Bed',
    },
    {
        img: Four,
        title: 'Bed',
    },
    {
        img: Five,
        title: 'Bed',
    },
    {
        img: Six,
        title: 'Bed',
    },
];

export default Checkout;
