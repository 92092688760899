import React, { useState, useEffect, useRef } from 'react';
import Card from './Card'
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CollageOne from '../assets/IMG_3635.webp';
import CollageTwo from '../assets/IMG_3636.webp';
import CollageThree from '../assets/IMG_3637.webp';
import CollageFour from '../assets/IMG_3638.webp';
import CollageFive from '../assets/IMG_3642.webp';
import CollageSix from '../assets/IMG_3644.webp';
import CollageSeven from '../assets/IMG_3781.webp';
import CollageEight from '../assets/FullSizeRender(1).webp';


const Collage = (props) => {


  return (
    <>
      {props.count === 2 ? (
        <div style={{ width: '100vw', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                {itemData.map((item) => (
                    <div key={item.img} style={{ display: 'inline-block', marginRight: 7 }}>
                        <img
                            style={{ opacity: 1, borderRadius: 30, width: 208, height: 208, objectFit: 'cover' }}
                            src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                            srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                        />
                    </div>
                ))}
            </div>
        </div>
      ) : (
        <ImageList cols={4} gap={7} rowHeight="auto">
          {itemData.map((item) => (
            <ImageListItem key={item.img}>
              <img
                style={{opacity: 1, borderRadius: 5, height: 208, fit: 'crop'}}
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}

    </>
    );
}


const itemData = [
  {
    img: CollageOne,
    title: 'Stores Using Kiosk 1',
  },
  {
    img: CollageTwo,
    title: 'Stores Using Kiosk 2',
  },
  {
    img: CollageThree,
    title: 'Stores Using Kiosk 3',
  },
  {
    img: CollageFour,
    title: 'Stores Using Kiosk 4',
  },
  {
    img: CollageFive,
    title: 'Stores Using Kiosk 5',
  },
  {
    img: CollageSix,
    title: 'Stores Using Kiosk 6',
  },
  {
    img: CollageSeven,
    title: 'Stores Using Kiosk 7',
  },
  {
    img: CollageEight,
    title: 'Stores Using Kiosk 8',
  },

];

const itemData2 = [
  {
    img: CollageOne,
    title: 'Stores Using Kiosk',
  },
  {
    img: CollageTwo,
    title: 'Stores Using Kiosk',
  },


];

export default Collage;
