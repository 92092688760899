import KioskImage from '../assets/kiosk_order.png';
import Cardboard from '../assets/cardboard.png';
import Manufacturing from '../assets/manufacturing.png';
import DashboardImg from '../assets/dashboard.png';
import KdsImage from '../assets/kds.png';
import Hidden from '@mui/material/Hidden';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EndProductKiosk from '../assets/kiosk_official_2.png';
import LoyaltyImage from '../assets/loyalty.png';
import OtaImage from '../assets/ota.png';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const About = (props) => {
  return (

    <Box mt={10}>
    <Hidden mdUp> {/* Mobile View */}
      <Grid           justifyContent="center"
                direction="row"
                alignItems="flex-start"
                container
                spacing={0}
 sx={{background: 'rgba(0,0,0,0.05)', borderRadius: 10, padding: 2}}
                >
        <Grid item xs={11} md={6}>

        <h1 style={{fontSize: 23}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br /> Admin Dashboard</h1>
        <p style={{fontSize: 16}} sx={{display: { xs: 'none', md: 'block'  }}} className="poppins-font">Explore our user-friendly dashboard, designed for effortless management of items.</p>

        </Grid>
        <Grid item xs={12} md={6}  container justifyContent="center">
        <img src={DashboardImg} style={{ border: '10px solid rgba(255,255,255,0.1)',width: '100%',            boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 5, }} />

        <p style={{padding: 5,fontSize: 14, color:'rgba(0,0,0,0.7)'}} sx={{display: { xs: 'none', md: 'block'  }}} className="poppins-font">Preview items images, drag and drop to rearrange, and easily add or remove dishes. Customize your selections and provide a hassle-free kiosk experience.</p>

        </Grid>
      </Grid>
    </Hidden>
    <Hidden smDown> {/* Desktop */}
      <Grid
                direction="row"
                alignItems="flex-start" container sx={{background: 'rgba(0,0,0,0.05)', borderRadius: 10, padding: 5}}
    direction="row">

        <Grid item xs={12} md={6} style={{paddingRight: 30}}  >

        <h1 sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Admin Dashboard</h1>
        <p sx={{display: { xs: 'none', md: 'block'  }}} className="poppins-font" style={{fontSize: 25}}>Explore our user-friendly dashboard, designed for effortless management of items. <br /><br />Preview items images, drag and drop to rearrange, and easily add or remove dishes. Customize your selections and provide a hassle-free kiosk experience.</p>

        </Grid>
        <Grid item xs={12} md={6}  container alignItems="center" >
        <img src={DashboardImg} style={{ width:'100%',     border: '15px solid rgba(255,255,255,0.1)',      boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 5, }} />


        </Grid>

      </Grid>
    </Hidden>

    <Hidden mdUp> {/* Mobile View */}
      <Grid  container mt={1} sx={{padding: 2}} spacing={0}
    direction="row">

        <Grid item xs={12} md={6}>

        <h1 style={{fontSize: 23}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Kitchen Display System </h1>
        <p style={{fontSize: 16}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font">Navigate seamlessly through our innovative kitchen display system, tailored to enhance your operational efficiency.</p>


        </Grid>
        <Grid item xs={12} md={6}  container justifyContent="center">
        <img src={KdsImage} style={{border: '10px solid rgba(255,255,255,0.1)', width: '100%',            boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 5, }} />

        <p style={{padding: 5,fontSize: 14, color:'rgba(0,0,0,0.7)'}} sx={{display: { xs: 'none', md: 'block'  }}} className="poppins-font">
Easily manage orders with the 'DONE' button after preparation and even send a pickup text to customers with a single click. This interface ensures your kitchen staff stays organized, reducing wait times and enhancing customer satisfaction.</p>

        </Grid>

    </Grid>
    </Hidden>
    <Hidden smDown> {/* Desktop View */}
      <Grid
        alignItems="flex-start" container mt={5} sx={{padding: 5}}
    direction="row">

        <Grid item xs={12} md={6} style={{paddingRight: 30}}>

        <h1 sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Kitchen Display System </h1>
        <p sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font" style={{fontSize: 25}}>Navigate seamlessly through our innovative kitchen display system, tailored to enhance your operational efficiency. <br /><br />
Easily manage orders with the 'DONE' button after preparation and even send a pickup text to customers with a single click. This interface ensures your kitchen staff stays organized, reducing wait times and enhancing customer satisfaction.
        </p>


        </Grid>
        <Grid item xs={12} md={6}  container alignItems="center" >
        <img src={KdsImage} style={{ width: '100%',       border: '15px solid rgba(255,255,255,0.1)',       boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 5, }} />


        </Grid>

      </Grid>
    </Hidden>


        <Hidden mdUp> {/* Mobile View */}
        <Grid   justifyContent="center"
                  alignItems="flex-start"
                  container mt={1} mb={5} sx={{background: 'rgba(0,0,0,0.05)', borderRadius: 10, padding: 2}}
      direction="row" spacing={0}>

          <Grid item xs={11} md={6}>

          <h1  style={{fontSize: 23}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Rewards Program </h1>
          <p  style={{fontSize: 16}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font" >
          Your complete rewards management platform, tailored for your shop. Oversee point accumulations, manage member sign-ups, and curate exclusive deals and offers to enhance customer loyalty.
          </p>

          </Grid>
          <Grid item xs={12} md={6}  container justifyContent="center">
          <img src={LoyaltyImage} style={{border: '10px solid rgba(255,255,255,0.1)', width: '70%', boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 20, }} />

                <p sx={{display: { xs: 'block', md: 'none' }}} className="poppins-font" style={{fontSize: 14, color:'rgba(0,0,0,0.7)'}}>
                Ensure your patrons are aligned with the terms and encourage them to authenticate with a digital signature. Streamline the rewards process, bolster customer engagement, and watch your business flourish.

                </p>

          </Grid>

        </Grid>
        </Hidden>
        <Hidden smDown> {/* Desktop View */}
        <Grid  container mt={5} sx={{background: 'rgba(0,0,0,0.05)', borderRadius: 10, padding: 5}}
      direction="row" spacing={3}>

          <Grid item xs={12} md={6} style={{paddingRight: 30}}>

          <h1 sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Rewards Program </h1>
          <p sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font" style={{fontSize: 25}}>
          Your complete rewards management platform, tailored for your shop. Oversee point accumulations, manage member sign-ups, and curate exclusive deals and offers to enhance customer loyalty.

          <br /><br />
          Ensure your patrons are aligned with the terms and encourage them to authenticate with a digital signature. Streamline the rewards process, bolster customer engagement, and watch your business flourish.

          </p>


          </Grid>
          <Grid item xs={12} md={6}  container justifyContent="center">
          <img src={LoyaltyImage} style={{   border: '15px solid rgba(255,255,255,0.1)',width: '90%', boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 5, }} />


          </Grid>

        </Grid>
        </Hidden>


    <Hidden mdUp> {/* Mobile View */}
    <Grid   justifyContent="center"
              alignItems="flex-start"
              container mt={1} mb={5} sx={{ borderRadius: 10, padding: 2}}
  direction="row" spacing={0}>

      <Grid item xs={11} md={6}>

      <h1  style={{fontSize: 23}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Over-The-Air Updates </h1>
      <p  style={{fontSize: 16}} sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font" >
     Our kiosk benefits from daily, weekly, and monthly updates to provide the most up-to-date user experience.
      </p>

      </Grid>
      <Grid item xs={12} md={6}  container justifyContent="center">
      <img src={OtaImage} style={{border: '10px solid rgba(255,255,255,0.1)', width: '100%', boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 20, }} />
      <p sx={{display: { xs: 'block', md: 'none' }}} className="poppins-font" style={{paddingLeft: 10,fontSize: 14, color:'rgba(0,0,0,0.7)'}}>Experience a constantly evolving, user-centric kiosk system. Our kiosk evolves daily!</p>


      </Grid>

    </Grid>
    </Hidden>
    <Hidden smDown> {/* Desktop View */}
    <Grid  container mt={5} sx={{borderRadius: 10, padding: 5}}
  direction="row" spacing={3}>

      <Grid item xs={12} md={6} style={{paddingRight: 30}}>

      <h1 sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font"><CheckBoxIcon htmlColor='green' /> <span style={{fontSize: 20, position: 'relative', bottom: 5}}>INCLUDED</span><br />Over-The-Air Updates </h1>
      <p sx={{display: { xs: 'none', md: 'block' }}} className="poppins-font" style={{fontSize: 25}}>
Experience a constantly evolving, user-centric kiosk system. <br /> <br /> Our kiosk benefits from daily, weekly, and monthly updates to provide the most up-to-date user experience.
      </p>


      </Grid>
      <Grid item xs={12} md={6}  container justifyContent="center">
      <img src={OtaImage} style={{             border: '15px solid rgba(255,255,255,0.1)',
width: '85%', boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.23)',borderRadius: 20, }} />


      </Grid>

    </Grid>
    </Hidden>

    <Grid  mt={7}  container
    direction="row"
     justifyContent="center"
     alignItems="flex-start">
  <Grid  sx={{display: { xs: 'none', md: 'block' }}} xs={12} md={4} pr={3}>
  <div
    style={{
       borderRadius: 30,
      zIndex: 1,
      width: '100%',
      overflow: 'hidden', textAlign: 'left', paddingTop: 0, paddingBottom: 30, paddingLeft: 35,
      backgroundImage: 'linear-gradient(to right bottom, #3e447f, #794f83, #a36081, #c0787f, #d29583, #d69b7e, #d7a27a, #d6aa76, #d09a5e, #ca8946, #c4782f, #be6616)'
    }}>
    <h2 className="poppins-font" style={{color: 'white', fontWeight: 500}}>1. How we started</h2>
    <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400, width: '95%'}}>It all began as a simple spark, a vision. Amidst humble cardboards and rough sketches, we saw potential for something transformative</p>

    <img
      style={{
        border: '10px solid rgba(255,255,255,0.1)',
        boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.63)',

        width: '93%',
        zIndex: 2,
        opacity: 1,
        borderRadius:30,
      }}
      src={Cardboard}
    />


  </div>
  </Grid>
  <Grid  sx={{display: { xs: 'block', md: 'none' }}} xs={12} md={5} pl={5} pt={5}>
  <div
    style={{
       borderRadius: 30,
      zIndex: 1,
      width: '100%',
      overflow: 'hidden', textAlign: 'left', paddingTop: 0,paddingBottom: 20, paddingLeft: 35,
      backgroundImage: 'linear-gradient(to right bottom, #3e447f, #794f83, #a36081, #c0787f, #d29583, #d69b7e, #d7a27a, #d6aa76, #d09a5e, #ca8946, #c4782f, #be6616)'
    }}>
    <h2 className="poppins-font" style={{color: 'white', fontWeight: 500, }}>How we started</h2>
    <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400,width: '95%'}}>It all began as a simple spark, a vision. Amidst humble cardboards and rough sketches, we saw potential for something transformative</p>

    <img
      style={{
        border: '10px solid rgba(255,255,255,0.1)',
        boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.63)',

        width: '90%',
        zIndex: 2,
        opacity: 1,
        borderRadius:30,
      }}
      src={Cardboard}
    />
  </div>
  </Grid>
      <Grid  sx={{display: { xs: 'none', md: 'block' }}}  xs={12} md={4} pr={3} >
      <div
        style={{
           borderRadius: 30,
          zIndex: 1,
          width: '100%',
          overflow: 'hidden', textAlign: 'left', paddingTop: 0, paddingBottom: 20, paddingLeft: 35,
          backgroundImage: 'linear-gradient(to bottom, #3e447f, #794f83, #a36081, #c0787f, #d29583, #d69b7e, #d7a27a, #d6aa76, #d09a5e, #ca8946, #c4782f, #be6616)'
        }}>
        <h2 className="poppins-font" style={{color: 'white', fontWeight: 500,}}>2. How we built & engineered</h2>
        <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400,  width: '95%'}}>It wasn't just about tools and codes; it was a journey. Through sheer dedication, extensive research, and countless all-nighters, we meticulously crafted our dream, piece by piece.</p>

        <div class="bg-animation">
          <div id='stars2'></div>
        </div>
        <img
          style={{
            border: '10px solid rgba(255,255,255,0.1)',

            boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.63)',
            position: 'relative',
            right: 0,
            width: '93%',
            zIndex: 2,
            opacity: 1,
            borderRadius:30,
          }}
          src={Manufacturing}
        />
      </div>
      </Grid>
      <Grid  sx={{display: { xs: 'block', md: 'none' }}}  xs={12} md={4} pt={2} pr={5}>
      <div
        style={{
           borderRadius: 30,
          zIndex: 1,
          width: '100%',
          overflow: 'hidden', paddingBottom: 20,textAlign: 'left', paddingTop: 0,  paddingLeft: 35,
          backgroundImage: 'linear-gradient(to bottom, #3e447f, #794f83, #a36081, #c0787f, #d29583, #d69b7e, #d7a27a, #d6aa76, #d09a5e, #ca8946, #c4782f, #be6616)'
        }}>
        <h2 className="poppins-font" style={{color: 'white', fontWeight: 500, }}>How we built & engineered</h2>
        <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400, width: '95%'}}>It wasn't just about tools and codes; it was a journey. Through sheer dedication, extensive research, and countless all-nighters, we meticulously crafted our dream, piece by piece.</p>

        <div class="bg-animation">
          <div id='stars2'></div>
        </div>
        <img
          style={{
            position: 'relative',
            right: 0,
            width: '90%',
            zIndex: 2,
            opacity: 1,
            borderRadius:30,
            border: '10px solid rgba(255,255,255,0.1)',

          }}
          src={Manufacturing}
        />
      </div>
      </Grid>
      <Grid  sx={{display: { xs: 'none', md: 'block' }}}  xs={12} md={4}>
      <div
        style={{
           borderRadius: 30,
          zIndex: 1,
          width: '100%',
          overflow: 'hidden', textAlign: 'left', paddingTop: 0, paddingLeft: 35, paddingBottom: 30,
          backgroundImage: 'linear-gradient(to left bottom, #3e447f, #794f83, #a36081, #c0787f, #d29583, #d69b7e, #d7a27a, #d6aa76, #d09a5e, #ca8946, #c4782f, #be6616)'
        }}>
        <h2 className="poppins-font" style={{color: 'white', fontWeight: 500,}}>3. How we launched</h2>
        <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400, width: '95%'}}>When we finally unveiled our creation, the result was not just a product, but a testament to our passion and commitment. It was more than we had hoped for: a beautiful convergence of design, functionality, and innovation that resonated with many.

</p>


        <img
          style={{
            border: '10px solid rgba(255,255,255,0.1)',
            boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.63)',
            position: 'relative',
            right: 0,
            width: '92%',
            zIndex: 2,
            opacity: 1,
            borderRadius:30,
          }}
          src={EndProductKiosk}
        />
      </div>
      </Grid>
      <Grid  sx={{display: { xs: 'block', md: 'none' }}}  xs={12} md={5} pl={5} pl={5} pt={2}>
      <div
        style={{
           borderRadius: 30,
          zIndex: 1, paddingLeft: 35,
          width: '100%',
          overflow: 'hidden', textAlign: 'left', paddingTop: 0, paddingBottom: 30,
          backgroundImage: 'linear-gradient(to left bottom, #3e447f, #794f83, #a36081, #c0787f, #d29583, #d69b7e, #d7a27a, #d6aa76, #d09a5e, #ca8946, #c4782f, #be6616)'
        }}>
        <h2 className="poppins-font" style={{color: 'white', fontWeight: 500,}}>How we launched</h2>
        <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400, width: '95%'}}>When we finally unveiled our creation, the result was not just a product, but a testament to our passion and commitment. It was more than we had hoped for: a beautiful convergence of design, functionality, and innovation that resonated with many.

</p>


        <img
          style={{
            border: '10px solid rgba(255,255,255,0.1)',
            boxShadow: '10px 10px 54px -3px rgba(0,0,0,0.63)',

            position: 'relative',
            right: 0,
            width: '90%',
            zIndex: 2,
            opacity: 1,

            borderRadius:30,
          }}
          src={EndProductKiosk}
        />
      </div>
      </Grid>
    </Grid>

    <div
    className="poppins-font"
     style={{
      marginTop: 50,
      borderRadius: 30,
      backgroundImage: 'linear-gradient(to right bottom, #4455ff, #4454f7, #4353ee, #4352e6, #4351de, #4a4fdc, #504eda, #554cd8, #6249db, #6f44dd, #7c3fdf, #8839e0)',
      width: '100%',
      padding: 20,
      paddingLeft: 30,
      paddingBottom: 50,

    }}>
    <Grid container>
      <Grid item xs>
        <h1 className="poppins-font" style={{fontSize: 23, color: 'white'}}>What to expect with your new kiosk <br/> <small style={{color: 'rgb(255,255,255,0.8)', fontWeight: 400}}>All included Features, Hardware, and Software</small></h1>
      </Grid>
      <Grid container item>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>01.</h2>
        24-inch Big Kiosk

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>02.</h2>
        Admin Dashboard

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>03.</h2>
        Integrated Rewards Program

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>04.</h2>
        Unlimited Kitchen Display

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>05.</h2>
        Sales Reports

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>06.</h2>
        Control Kiosk Remotely

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>07.</h2>
        Online Ordering

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>08.</h2>
        Apple Pay, Samsung Pay,  Tap & Dip Credit Card Processing

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>09.</h2>
        Over-The-Air Updates

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>10.</h2>
        EPSON Kitchen Ticket Printing System

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>11.</h2>
        Free Delivery

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>12.</h2>
        Free Installation

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>13.</h2>
        Free Training

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>14.</h2>
       24/7 Customer Support

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>15.</h2>
        Table Management System

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>16.</h2>
        Integrated Waitlist System

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>17.</h2>
        SMS & Email Marketing

        </Grid>
        <Grid style={{ color: 'white'}} xs={6} md={3}><h2>18.</h2>
        SMS Receipt & Notifications

        </Grid>
      </Grid>
    </Grid>
    </div>
    </Box>

  )
}

export default About;
