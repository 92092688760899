import React, { useState, useEffect, useRef } from 'react';
import './header.css';
import '../App.css';
import Collage from './Collage'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grow from '@mui/material/Grow';
import KioskImage from '../assets/kiosk_order.png';
import KioskImage2 from '../assets/migak_touching_1.1.7.png';
import Hidden from '@mui/material/Hidden';
import KioLogo from '../assets/61d8c0fe1e774f433535395a_kiostart_logo.svg';
import OrangeLogo from '../assets/kiologo.png';

const TARGET_SHOP_ARR = ['restaurants', 'cafes', 'boba shops', 'tea houses', 'all'];

const Header = (props) => {
  const [targetShops, setTargetShops] = useState(TARGET_SHOP_ARR);
  const [currentIndex, setCurrentIndex] = useState([
    0
  ]);

  const [up, setUp] = useState(false);
  const [down, setDown] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    let index = 0;

    const changeTarget = () => {
      if (!up) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % TARGET_SHOP_ARR.length);
      }
      setUp(prev => !prev) // Time should match with the transition timeout
    };

    const intervalId = setInterval(changeTarget, 950); // Change every 3 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, [targetShops, up]);


  return (
    <Grid container>
      <Hidden lgDown>
      <Grid item pt={8} xs={12}>
      <div  className="logo-font" style={{fontSize: 32, fontWeight: 600, color: 'rgba(0,0,0,0.7)', textShadow: '1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white'}}>
      <span style={{fontSize: 22, position: 'relative'}}><img style={{width: 27.5, position: 'relative', top: 5,  left: 2,  textShadow: '0.5px 0.5px 0 rgba(255,255,255,0.1), -0.5px -0.5px 0 white, 0.5px -0.5px 0 rgba(255,255,255,0.1), -0.5px 0.5px 0 rgba(255,255,255,0.1)'}} src={OrangeLogo} /></span> <span style={{color: 'rgba(0,0,0,0.7)'}}><span style={{fontSize: 31}}>Ki</span>o</span><span style={{fontWeight: 400, color:'rgba(0,0,0,0.7)'}}>start</span><span style={{display:'inline-block', background: 'orange', width: 134, height: 12, position: 'relative', right: 126, top: 6, zIndex: -1, borderRadius: 100, borderTopRightRadius: 9, borderBottomLeftRadius: 9}}></span>
      </div>
      </Grid>
      <Grid item xs={8} pr={4}>
        <Grid
          spacing={0}
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start">
            <Grid item>
                <h1 className="poppins-font" sx={{color: 'rgba(0,0,0,0.73)', height: 125, fontSize: 100, fontWeight: 700}}>
                  Our kiosk is your virtual host for <br /> managing <span style={{background: 'orange'}}>seatings</span>, orders, and guest flow
                </h1>
            </Grid>
            <Grid item>
              <Collage />
              <Typography sx={{color: 'rgba(0,0,0,0.8)', height: 150, fontSize: 26, float: 'right'}} variant="h2" gutterBottom>
                Trusted by many
              </Typography>
            </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
      <Box>
          <div
            style={{
               borderRadius: 30,
              zIndex: 1,
              width: '100%',
              overflow: 'hidden',
              height: 650, textAlign: 'left', paddingTop: 0,
              backgroundImage: 'linear-gradient(to left bottom, #cd700a, #dc5456, #c3568b, #8d66a6, #5670a1)',
              position: 'relative',  // <== Added this line
            }}>
            <h2 className="poppins-font" style={{color: 'white', fontWeight: 500, paddingLeft: 35,}}>Beautiful to look at</h2>
            <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400, paddingLeft: 35, width: '95%'}}>Our kiosk manages everything: from placing orders to handling guest flow. We've got all your needs addressed.</p>

            <div class="bg-animation">
              <div id='stars2'></div>
            </div>
            <img
              style={{
                position: 'absolute',  // <== Changed to absolute
                right: 0,
                width: '100%',
                zIndex: 2,
                opacity: 1,
                left: 0,
                bottom: 0,  // <== Set to 0
                borderRadius:30,
              }}
              src={KioskImage}
            />
          </div>
      </Box>
  </Grid>
      </Hidden>


      <Hidden lgUp>
      <Grid item xs={12} >
        <Grid
          style={{overflowX: 'hidden'}}
          spacing={0}
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="flex-start">
          <Box sx={{pt: 2}}>
            <Grid item xs>
            <div  className="logo-font" style={{fontSize: 32, fontWeight: 600, color: 'rgba(0,0,0,0.7)!important', textShadow: '1px 1px 0 white, -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white'}}>
            <span style={{fontSize: 22, position: 'relative'}}><img style={{width: 27.5, position: 'relative', top: 5,  left: 2,  textShadow: '0.5px 0.5px 0 rgba(255,255,255,0.1), -0.5px -0.5px 0 white, 0.5px -0.5px 0 rgba(255,255,255,0.1), -0.5px 0.5px 0 rgba(255,255,255,0.1)'}} src={OrangeLogo} /></span> <span style={{ color: 'rgba(0,0,0,0.7)'}}><span style={{fontSize: 31}}>Ki</span>o</span><span style={{fontWeight: 400, color:'rgba(0,0,0,0.7)'}}>start</span><span style={{display:'inline-block', background: 'orange', width: 134, height: 12, position: 'relative', right: 126, top: 6, zIndex: -1, borderRadius: 100, borderTopRightRadius: 9, borderBottomLeftRadius: 9}}></span>
            </div>
            </Grid>
            <Grid item xs>
              <Box sx={{mt: 3,  width: '92%'}}>
                <h1 className="poppins-font" sx={{color: 'rgba(0,0,0,0.73)', fontSize: 52, fontWeight: 700}}>
                  Our kiosk is your virtual host for <br /> managing <span style={{background: 'orange'}}>seatings</span>, orders, and guest flow
                </h1>
              </Box>
            </Grid>
            <Grid item xs>
              <Collage count={2} />

            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12}>
      <Box sx={{pt: 4}}>
        <div
          style={{
            position: 'relative',
             borderRadius: 30,
            zIndex: 1,
            width: '100%',
            overflow: 'hidden',
            height: 580, textAlign: 'left', paddingTop: 0,
            backgroundImage: 'linear-gradient(to left bottom, #cd700a, #dc5456, #c3568b, #8d66a6, #5670a1)'
          }}>
          <h2 className="poppins-font" style={{color: 'white', fontWeight: 500, paddingLeft: 35,}}>Beautiful to look at</h2>
          <p className="poppins-font" style={{position: 'relative', bottom: 15,color: 'white', fontWeight: 400, paddingLeft: 35, width: '95%'}}>Our kiosk manages everything: from placing orders to handling guest flow. We've got all your needs addressed.</p>

          <img
            style={{
              width: '100%',
              zIndex: 2,
              opacity: 1,
            }}
            src={KioskImage}
          />

                    <div class="bg-animation">
                      <div id='stars2'></div>
                    </div>
        </div>
      </Box>
      </Grid>
      </Hidden>

    </Grid>
  );
};

export default Header;
