import React from 'react';
import Collage from './header/Collage';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Header from './header'
import Intro from './intro'
import Action from './action'
import About from './about'
import Checkout from './checkout'

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container id="container" maxWidth={'xl'}>
        <Header />
        <Action />
        <About />
        <Checkout />
      </Container>
    </React.Fragment>
  );
}

export default App;
