import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box'
import Hidden from '@mui/material/Hidden';
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import ButtonGroup from '@mui/material/ButtonGroup';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Hand from '../assets/hand_universal.png';
import Kio from '../assets/kiosk_official2.png';
import Kio2 from '../assets/kiosk_order.png';
import Line from '../assets/line.png';
import WiatListFloat from '../assets/join_waitlist_float.png';
import ToGo from '../assets/togo.png';
import Menu from '../assets/menu.png';
import TableSelect from '../assets/choose_table.png';
import Tablet from '../assets/tablet.png';
import TabletItem from '../assets/table_item.png';
import TableSelectDetail from '../assets/table_item.png';
import Checkout from '../assets/checkout.png';
import CompletePage from '../assets/complete_page.png';
import Sms from '../assets/sms.png';
import '../App.css';

import KitchenDisplay from '../assets/kitchendisplay.png';

import Typography from '@mui/material/Typography';
import './intro.css'; // Assuming you save the above CSS in styles.css

const StepOne = (props) => {
  const isVisible = props.isVisible;
  const hasSticked = props.hasSticked;

  return (
    <>
    <Hidden lgDown>
      <div key="originalContent">
        {/* Your original content here */}
        <Box style={{position: 'relative', top: 60, zIndex: 1, textAlign: 'center'}} className={`fade-in-text ${isVisible && hasSticked ? 'visible' : ''}`} sx={{mt: 4}}>
          <p className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontSize: 30}} >
            <b  className="poppins-font" style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 26, marginRight: 2}}>1</b> Customers check-in via kiosk
          </p>
        </Box>
        <Box style={{ zIndex: 1, position: 'relative', top: 24, textAlign: 'center'}} className={`fade-in-text delay-005 ${isVisible && hasSticked ? 'visible' : ''}`} sx={{mt: 4}}>
        <p   className="poppins-font"  style={{color: 'rgba(255,255,255,0.39)', fontWeight: 300, fontSize:18.5}}>
          Customers check-in right away instead of waiting
        </p>
        </Box>

        <div style={{ position: 'relative', bottom: 70, width: '100%' }}>
        <img style={{zIndex: 999999, width: '96%', borderRadius: 12, opacity: 1, zIndex: 2, background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.2))',
}}  src={Kio}/>
        <img
          className={
            'visible hand-animation'
          }
          style={{
            transition: 'transform 1s ease-out,',
            transform: `translateX(10px)`,
            width: '20%', borderRadius: 12, opacity: 1, borderTopLeftRadius: 100, borderBottomRightRadius: 100, position: 'absolute',
                          bottom: '40%',
                          left: '27%'}}  src={Hand}/>
        <div style={{
          position: 'absolute',
          bottom: '66%',
          left: '3%',
          width: '33%',
        }}
          className={`fade-in-text2 delay-007 ${isVisible && hasSticked ? 'visible' : ''}`}>
          <img
            style={{
              opacity: 1,
              position: 'absolute',
              top: '137%',
              left: '33%',
              width: '75%',
              transform: 'rotate(30deg)'
            }}
            src={Line} />
            <Box
              style={{  position: 'absolute', bottom: 50, background: 'rgba(255,255,255,0.08)', zIndex: 10, width: '69%', textAlign: 'center', padding: 10, borderRadius:7,}}
              sx={{ mt: 4 }}
            >
              <Typography
                sx={{ color: 'rgba(255,255,255,0.59)', lineHeight: 1, fontWeight: 300, fontSize: 20, opacity: 1}}
                variant="small"

                gutterBottom
              >
               Ultra responsive <br/> touch screen
              </Typography>
            </Box>
        </div>
        <div style={{position: 'relative', bottom: 350, left: '55%'}}
        className={`fade-in-text2 delay-007 ${isVisible && hasSticked ? 'visible' : ''}`}>
          <img
            style={{
              opacity: 1,
              position: 'absolute',
              top: '81%',
              left: '10%',
              width: '20%',
              transform: 'rotate(-65deg)'
            }}
            src={Line} />
            <Box
              style={{ background: 'rgba(255,255,255,0.12)', position: 'absolute', left: 115, bottom: 55, zIndex: 10, width: 140, textAlign: 'center', padding: 10, borderRadius:7,}}
              sx={{ mt: 4 }}
            >
              <Typography
                sx={{ color: 'rgba(255,255,255,0.7)', lineHeight: 1, fontWeight: 300, fontSize: 20, opacity: 1}}
                variant="p"

                gutterBottom
              >
              Constructed <br />from steel
              </Typography>
            </Box>
        </div>

        </div>
      </div>
    </Hidden>
    <Hidden lgUp>
    <div key="originalContent" style={{ position: 'relative' }}>
        {/* Your original content here */}
        <Box style={{position: 'relative', top: 90, zIndex: 1, textAlign: 'center'}} className={`fade-in-text delay-005 ${isVisible && hasSticked ? 'visible' : ''}`} sx={{mt: 4}}>
          <p  className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize:22}}>
          <b  className="poppins-font" style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 18}}>1</b> Customers check-in via kiosk
          </p>
        </Box>
        <Box style={{ zIndex: 1, position: 'relative', top: 57, textAlign: 'center'}} className={`fade-in-text delay-005 ${isVisible && hasSticked ? 'visible' : ''}`} sx={{mt: 4}}>
        <p style={{color: 'rgba(255,255,255,0.39)', fontWeight: 300, fontSize:15.5}}>
          Customers check-in instead of waiting
        </p>
        </Box>

        <div style={{ position: 'relative', bottom: 0,top: 5, width: '100%' }}>
        <img style={{zIndex: 999999, width: '100%', borderRadius: 12, background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))', opacity: 1}}  src={Kio}/>
        <img
          className={
            isVisible ? 'visible hand-animation' : ''
          }

          style={{
            transition: 'transform 1s ease-out,',
            transform: `translateX(10px)`,
            width: '20%', borderRadius: 12, opacity: 1, borderTopLeftRadius: 100, borderBottomRightRadius: 100, position: 'absolute',
                          bottom: '40%',
                          left: '27%'}}  src={Hand}/>

        <div style={{position: 'absolute', top: '20%', right: '60%'}}
          className={`fade-in-text2 delay-007 ${isVisible && hasSticked ? 'visible' : ''}`}>

            <Box
              style={{ background: 'rgba(255,255,255,0.08)', zIndex: 10, width: 129, textAlign: 'center', padding: 10, borderRadius:7,}}
              sx={{ mt: 4 }}
            >
              <Typography
                sx={{ color: 'rgba(255,255,255,0.59)', lineHeight: 0.5, fontWeight: 300, fontSize: 15, opacity: 1}}
                variant="p"

                gutterBottom
              >
               Ultra responsive touch screen
              </Typography>
            </Box>
        </div>

        <div style={{position: 'absolute', top: 200, right: '25%'}}
          className={`fade-in-text2 delay-007 ${isVisible && hasSticked ? 'visible' : ''}`}>

            <Box
              style={{ background: 'rgba(255,255,255,0.12)', position: 'relative', left: 82, bottom: 30, zIndex: 10, width: 100, textAlign: 'center', padding: 10, borderRadius:7,}}
              sx={{ mt: 4 }}
            >
              <Typography
                sx={{ color: 'rgba(255,255,255,0.7)', lineHeight: 1, fontWeight: 300, fontSize: 15, opacity: 1}}
                variant="p"

                gutterBottom
              >
              Constructed <br /> from steel
              </Typography>
            </Box>
        </div>

        </div>
      </div>
    </Hidden>
    </>
  );
}

const StepTwo = (props) => {
  const isVisible = props.isVisible;
  const hasSticked = props.hasSticked;
  const hasScrolledPastThreshold = props.activeStep

  return (
    <>
    <Hidden lgDown>

    <div  style={{transition: 'all 0.3s ease', maxWidth: '100%', margin: '0 auto',}}>
      {/* Your original content here */}
      <Box key={hasScrolledPastThreshold ? 'post-threshold' : 'pre-threshold'} style={{position: 'relative', top: 60, zIndex: 1, textAlign: 'center'}} className={`fade-in-text ${isVisible ? 'visible' : ''}`} sx={{mt: 4}}>
      <p className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontSize: 30}} >
          <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 30}}>2</b> Customers order prior to seatings
        </p>
      </Box>
      <div style={{ position: 'relative', width: '100%', bottom: 22 }}>
          {/* Kio2 image */}
          <img
              style={{
                  zIndex: 999999,
                  width: '96%',
                  borderRadius: 12,
                  background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
                  opacity: 1,
              }}
              src={Kio2}
          />

          {/* Hand image */}
          <img
              className={isVisible ? 'hand-animation' : ''}
              style={{
                  width: '20%',
                  borderRadius: 12,
                  opacity: 1,
                  borderTopLeftRadius: 100,
                  borderBottomRightRadius: 100,
                  position: 'absolute',
                  bottom: '37%', // adjust this based on your design
                  left: '27.6%',  // adjust this based on your design
              }}
              src={Hand}
          />
          <img style={{position: 'absolute', top: '57%', right: '25%', zIndex: 999, zIndex: 99999, width: '23%', transform: 'rotate(-53deg)'}} src={Line} />
          <img  className={hasScrolledPastThreshold ? 'togo-float' : ''} src={ToGo} />
          <img style={{position: 'absolute', top:'38%', right:'50%',  bottom: 100, zIndex: 999,  width: '25%', transform: 'rotate(13deg)'}} src={Line} />
          <img  className={hasScrolledPastThreshold ? 'menu-float' : ''}  src={Menu} />

      </div>


    </div>
    </Hidden>
    <Hidden lgUp>
    <div>
      {/* Your original content here */}
      <Box key={hasScrolledPastThreshold ? 'post-threshold' : 'pre-threshold'} style={{position: 'relative', top: 90, zIndex: 1, textAlign: 'center'}} className={`fade-in-text ${isVisible ? 'visible' : ''}`} sx={{mt: 4}}>
        <p  className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize:22}}>
        <b   className="poppins-font" style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 18}}>2</b> Customers order prior to seatings
        </p>
      </Box>

      <div style={{ position: 'relative', width: '100%', top: 61, }}>
          {/* Kio2 image */}
          <img
              style={{
                  zIndex: 999999,
                  width: '100%',
                  borderRadius: 12,
                  background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
                  opacity: 1,
              }}
              src={Kio2}
          />

          {/* Hand image */}
          <img
              className={isVisible ? 'hand-animation' : ''}
              style={{
                  width: '20%',
                  borderRadius: 12,
                  opacity: 1,
                  borderTopLeftRadius: 100,
                  borderBottomRightRadius: 100,
                  position: 'absolute',
                  bottom: '37%', // adjust this based on your design
                  left: '27.6%',  // adjust this based on your design
              }}
              src={Hand}
          />
          <img style={{position: 'absolute', width: '37%', right: '2%', top: '45%', zIndex: 99988, opacity: 0.95}} src={ToGo} />
          <img style={{position: 'absolute', zIndex: 998, width: '24%', right: '75%', top: '19%' }} src={Menu} />

      </div>

    </div>
    </Hidden>
    </>
  );
}

const StepThree = (props) => {
  const isVisible = props.isVisible;
  const [translateValue, setTranslateValue] = useState('0%');
  const [translateValue2, setTranslateValue2] = useState('100%');
  const [isSlideInVisible, setIsSlideInVisible] = useState(false);
  const [moveHand, setMoveHand] = useState(false);
  const [_class, setClass] = useState('hand hand-animation')
  const [opacity, setOpacity] = useState(1);
  const [opacity2, setOpacity2] = useState(0);


  useEffect(() => {
    if (isVisible) {
      setTranslateValue(isSlideInVisible ? '-37%' : '0%');
      setOpacity(isSlideInVisible ? 0.05 : 1)
    }

    if (isSlideInVisible) {
      setTranslateValue2('0%')
    } else {
      setTranslateValue2('100%')

    }
  }, [isVisible, isSlideInVisible]);


  useEffect(() => {
    if (props.activeStep === 3) {
      setIsSlideInVisible(true)
      setMoveHand(false);
      setClass('hand hand-animation')

    } else if (props.activeStep === 4) {
      setMoveHand(true);
      setIsSlideInVisible(false)
      setClass('hand-move hand-animation')

    } else {
      setIsSlideInVisible(false)
    }
  }, [props.activeStep])



  return (
    <>
    <Hidden lgDown>
    <div
      key={props.activeStep}
      style={{
        position: 'relative',
        transition: 'all 0.3s ease',
        margin: '0 auto',
      }}
    >
      {/* Content that will slide */}
      <div
        style={{
          transition: 'transform 0.3s ease-out, opacity 1s ease-out', // <- Add opacity here
          transform: `translateX(${translateValue})`,
          opacity: opacity
        }}
      >
        <Box
          style={{ position: 'relative', top: 60, textAlign: 'center' }}
          className={`${isVisible && translateValue == '0%' ? 'fade-in-text' : ''} ${isVisible ? 'visible' : ''}`}
          sx={{ mt: 4 }}
        >
        <p className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontSize: 30}} >

          <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 30}}>{moveHand ? 4 : 3}</b>
            {moveHand ? ' Customers complete check-in process' : ' Customers select table'}
          </p>
        </Box>


        <div style={{ position: 'relative', width: '100%', bottom: 22 }}>
          {/* Kio2 image */}
          <img
            style={{
              zIndex: 999999,
              width: '96%',
              borderRadius: 12,
              background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
              opacity: 1,
            }}
            src={TableSelect}
          />

          {/* Hand image */}
          <img
            className={_class}

            src={Hand}
          />
          <img style={{opacity: moveHand ? 0 : 1}} className={!isSlideInVisible ? 'waitlist-float' : ''} src={WiatListFloat} />

        </div>
      </div>

      <div
       style={{
        position: 'absolute',
        top: 20,       // adjusted to 0 to start from the top of the parent
        left: 0,
        right: 0,    // added to ensure it stretches horizontally
        bottom: 0,   // added to ensure it stretches vertically
        zIndex: 12,
        visibility: isSlideInVisible ? 'visible' : 'hidden',
        transition: 'transform 0.33s ease-out',
        transitionDelay: '0.05s',
        transform: `translateY(${translateValue2})`,
            }}>
        <SlideInComponent />
      </div>

    </div>
    </Hidden>
    <Hidden lgUp>
    <div
      key={props.activeStep}
      style={{
        position: 'relative',
        transition: 'all 0.3s ease',
        margin: '0 auto',
      }}
    >
      {/* Content that will slide */}
      <div
        style={{
          transition: 'transform 0.3s ease-out, opacity 1s ease-out', // <- Add opacity here
          transform: `translateX(${translateValue})`,
          opacity: opacity
        }}
      >
        <Box
          style={{ position: 'relative', top: 90,  textAlign: 'center' }}
          className={`${isVisible && translateValue == '0%' ? 'fade-in-text' : ''} ${isVisible ? 'visible' : ''}`}
          sx={{ mt: 4 }}
        >
          <p
            style={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 22 }}
             className="poppins-font"
          >
          <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 18}}>{moveHand ? 4 : 3}</b>
            {moveHand ? ' Customers complete check-in process' : ' Customers select table'}
          </p>
        </Box>

        <div style={{ position: 'relative', width: '100%', top: 61 }}>
          {/* Kio2 image */}
          <img
            style={{
              zIndex: 999999,
              width: '100%',
              borderRadius: 12,
              background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
              opacity: 1,
            }}
            src={TableSelect}
          />

          {/* Hand image */}
          <img
            className={_class}

            src={Hand}
          />
          <img style={{width: '70%',opacity: moveHand ? 0 : 1}} className={!isSlideInVisible ? 'waitlist-float' : ''} src={WiatListFloat} />
        </div>
      </div>

      <div
       style={{
        position: 'absolute',
        left: 0,
        right: 0,    // added to ensure it stretches horizontally
        bottom: 0,   // added to ensure it stretches vertically
        zIndex: 12,
        visibility: isSlideInVisible ? 'visible' : 'hidden',
        transition: 'transform 0.33s ease-out',
        transitionDelay: '0.05s',
        transform: `translateY(${translateValue2})`,
            }}>
        <SlideInComponent />
      </div>

    </div>
    </Hidden>

    </>
  );
}

const StepFour = (props) => {
  const isVisible = props.isVisible;
  const [translateValue, setTranslateValue] = useState('0%');
  const [translateValue2, setTranslateValue2] = useState('100%');
  const [isSlideInVisible, setIsSlideInVisible] = useState(false);

  const [opacity, setOpacity] = useState(1);
  const [opacity2, setOpacity2] = useState(0);


  useEffect(() => {
    if (props.activeStep === 5) {
      setTimeout(()=>{
        setIsSlideInVisible(true)
      }, 2000)
    } else {
      setIsSlideInVisible(false)

    }
  }, [props.activeStep])




  useEffect(() => {
    if (isVisible) {
      setTranslateValue(isSlideInVisible ? '-37%' : '0%');
      setOpacity(isSlideInVisible ? 0.05 : 1)
    }

    if (isSlideInVisible) {
      setTranslateValue2('0%')
    } else {
      setTranslateValue2('100%')

    }
  }, [isVisible, isSlideInVisible]);


  return (
    <>
    <Hidden lgDown>

    <div
      key={props.activeStep}
      style={{
        position: 'relative',
        transition: 'all 0.3s ease',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {/* Content that will slide */}
      <div
        style={{
          transition: 'transform 0.3s ease-out, opacity 1s ease-out', // <- Add opacity here
          transform: `translateX(${translateValue})`,
          opacity: opacity
        }}
      >
        <Box
          style={{ position: 'relative', top: 60, zIndex: 1, textAlign: 'center' }}
          className={`${isVisible && translateValue == '0%' ? 'fade-in-text' : ''} ${isVisible ? 'visible' : ''}`}
          sx={{ mt: 4 }}
        >
        <p className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontSize: 30}} >

            <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 30}}>5</b>&nbsp;Order complete!
          </p>
        </Box>

        <div style={{ position: 'relative', width: '100%', bottom: 22 }}>
          {/* Kio2 image */}
          <img
            style={{
              zIndex: 999999,
              width: '96%',
              borderRadius: 12,
              background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
              opacity: 1,
            }}
            src={CompletePage}
          />

        </div>
      </div>

      <div
        style={{
        position: 'absolute',
        top: 0,       // adjusted to 0 to start from the top of the parent
        left: 0,
        right: 0,    // added to ensure it stretches horizontally
        bottom: 90,   // added to ensure it stretches vertically
        zIndex: 12,
        visibility: isSlideInVisible ? 'visible' : 'hidden',
        transition: 'transform 0.33s ease-out',
        transitionDelay: '0.05s',
        transform: `translateY(${translateValue2})`,
            }}>
        <SlideInComponent2 />
      </div>

    </div>
    </Hidden>
    <Hidden lgUp>

        <div
          key={props.activeStep}
        >
          {/* Content that will slide */}
          <div
            style={{
              transition: 'transform 0.3s ease-out, opacity 1s ease-out', // <- Add opacity here
              transform: `translateX(${translateValue})`,
              opacity: opacity
            }}
          >
            <Box
              style={{ position: 'relative', top: 90, zIndex: 1, textAlign: 'center' }}
              className={`${isVisible && translateValue == '0%' ? 'fade-in-text' : ''} ${isVisible ? 'visible' : ''}`}
              sx={{ mt: 4 }}
            >
            <p className="poppins-font" style={{color: 'rgba(255,255,255,0.69)', fontSize: 22}} >

                <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 18}}>5</b>&nbsp;Order complete!
              </p>
            </Box>

            <div style={{ position: 'relative', width: '100%', top: 61, }}>
              {/* Kio2 image */}
              <img
                style={{
                  zIndex: 999999,
                  width: '100%',
                  borderRadius: 12,
                  background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
                  opacity: 1,
                }}
                src={CompletePage}
              />

            </div>
          </div>

          <div
            style={{
            position: 'absolute',
            top: 0,       // adjusted to 0 to start from the top of the parent
            left: 0,
            right: 0,    // added to ensure it stretches horizontally
            bottom: 90,   // added to ensure it stretches vertically
            zIndex: 12,
            visibility: isSlideInVisible ? 'visible' : 'hidden',
            transition: 'transform 0.33s ease-out',
            transitionDelay: '0.05s',
            transform: `translateY(${translateValue2})`,
                }}>
            <SlideInComponent2 />
          </div>

        </div>
    </Hidden>
    </>
  );
}


const StepFive = (props) => {
  const isVisible = props.isVisible;
  const [translateValue, setTranslateValue] = useState('0%');
  const [translateValue2, setTranslateValue2] = useState('100%');
  const [isSlideInVisible, setIsSlideInVisible] = useState(false);
  const [isSlideInVisible2, setIsSlideInVisible2] = useState(false);

  const [opacity, setOpacity] = useState(1);
  const [opacity2, setOpacity2] = useState(0);


  useEffect(() => {
    if (props.activeStep === 6) {
      setTimeout(()=>{
        setIsSlideInVisible(false)
      }, 2000)
    } else {
      setIsSlideInVisible(false)

    }
  }, [props.activeStep])



  useEffect(() => {
    if (isVisible) {
      setTranslateValue(isSlideInVisible ? '-37%' : '0%');
      setOpacity(isSlideInVisible ? 0.05 : 1)
    } else {
      setTranslateValue(isSlideInVisible ? '-37%' : '0%');
      setOpacity(isSlideInVisible ? 0.05 : 1)
    }

    if (isSlideInVisible) {
      setTranslateValue2('0%')
    } else {
      setTranslateValue2('100%')

    }
  }, [isVisible, isSlideInVisible]);


  return (
    <>
    <Hidden lgDown>

    <div
      key={props.activeStep}
      style={{
        position: 'relative',
        transition: 'all 0.3s ease',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {/* Content that will slide */}
      <div
        style={{
          transition: 'transform 0.3s ease-out, opacity 1s ease-out', // <- Add opacity here
          transform: `translateX(${translateValue})`,
          opacity: opacity
        }}
      >
        <Box
          style={{ position: 'relative', top: 120, zIndex: 1, textAlign: 'center' }}
          className={`${isVisible && translateValue == '0%' ? 'fade-in-text' : ''} ${isVisible ? 'visible' : ''}`}
          sx={{ mt: 4 }}
        >
          <Typography
            sx={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 35 }}
            variant="p"
            gutterBottom
          >
            <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 30}}>6</b> Kitchen receives order using our KDS
          </Typography>
        </Box>

        <div style={{ position: 'relative', width: '100%', top:110 }}>
          {/* Kio2 image */}
          <img
            style={{
              zIndex: 999999,
              width: '96%',
              borderRadius: 12,
              background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
              opacity: 1,
            }}
            src={KitchenDisplay}
          />

        </div>
      </div>

      <div
        style={{
        position: 'absolute',
        top: 0,       // adjusted to 0 to start from the top of the parent
        left: 0,
        right: 0,    // added to ensure it stretches horizontally
        bottom: 90,   // added to ensure it stretches vertically
        zIndex: 12,
        visibility: isSlideInVisible ? 'visible' : 'hidden',
        transition: 'transform 0.33s ease-out',
        transitionDelay: '0.05s',
        transform: `translateY(${translateValue2})`,
            }}>
        <SlideInComponent3 />
      </div>
    </div>
    </Hidden>
    <Hidden lgUp>
    <div
      key={props.activeStep}
      style={{
        position: 'relative',
        transition: 'all 0.3s ease',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      {/* Content that will slide */}
      <div
        style={{
          transition: 'transform 0.3s ease-out, opacity 1s ease-out', // <- Add opacity here
          transform: `translateX(${translateValue})`,
          opacity: opacity
        }}
      >
        <Box
          style={{ position: 'relative', top: 120, zIndex: 1, textAlign: 'center' }}
          className={`${isVisible && translateValue == '0%' ? 'fade-in-text' : ''} ${isVisible ? 'visible' : ''}`}
          sx={{ mt: 4 }}
        >
          <p className="poppins-font"
            style={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 22 }}

          >
            <b style={{background: 'rgba(255,255,255,0.1)', paddingLeft:10, paddingRight:10, borderRadius:3, fontSize: 18}}>6</b> Kitchen receives order using our KDS
          </p>
        </Box>

        <div style={{ position: 'relative', width: '100%', top:110 }}>
          {/* Kio2 image */}
          <img
            style={{
              zIndex: 999999,
              width: '96%',
              borderRadius: 12,
              background: 'linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.07))',
              opacity: 1,
            }}
            src={KitchenDisplay}
          />

        </div>
      </div>

      <div
        style={{
        position: 'absolute',
        top: 0,       // adjusted to 0 to start from the top of the parent
        left: 0,
        right: 0,    // added to ensure it stretches horizontally
        bottom: 90,   // added to ensure it stretches vertically
        zIndex: 12,
        visibility: isSlideInVisible ? 'visible' : 'hidden',
        transition: 'transform 0.33s ease-out',
        transitionDelay: '0.05s',
        transform: `translateY(${translateValue2})`,
            }}>
        <SlideInComponent3 />
      </div>
    </div>
    </Hidden>
    </>
  );
}

const SlideInComponent = () => {
  return (
    <>
    <Hidden lgDown>
    <div style={{ maxWidth: '100%', margin: '0 auto', position: 'relative' }}>
      <Box
        style={{ position: 'relative', top: 210, zIndex: 1, textAlign: 'center' }}
        className={`fade-in-text visible`}
        sx={{ mt: 4 }}
      >
        <Typography
          sx={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 35 }}
          variant="p"
          gutterBottom
        >
        Staff gets notified in real-time
        </Typography>
      </Box>
      <div
        style={{
          position: 'relative', // Fixed position
          bottom: 0, // Fixed at the bottom
          top: 280,
          left: 0,
          right: 0, // Stretch to full width
          zIndex: 999999, // Adjust the z-index as needed
        }}
      >
        <img
          style={{
            width: '100%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={Tablet}
        />
      </div>
      <div style={{ position: 'relative', bottom: 300, right: 200 }}>
        <img
          style={{
            position: 'relative',
            bottom: 200,
            left: 0,
            zIndex: 999999,
            width: '30%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={TableSelectDetail}
        />
      </div>
      <div style={{ position: 'relative', bottom: 330, left: 0 }}>
        <img
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '95%',
            zIndex: 999999,
            width: '30%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={Checkout}
        />
      </div>
    </div>
    </Hidden>
    <Hidden lgUp>
    <div style={{ maxWidth: '100%', margin: '0 auto', position: 'relative' }}>
      <Box
        style={{ position: 'relative', bottom: 190, zIndex: 1, textAlign: 'center' }}
        className={`fade-in-text visible poppins-font`}
        sx={{ mt: 4 }}
      >
        <p
          style={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 18 }}
        >
        Staff gets notified in real-time
        </p>
      </Box>
      <div
        style={{
          position: 'relative', // Fixed position
          bottom: 150, // Fixed at the bottom

          left: 0,
          right: 0, // Stretch to full width
          zIndex: 999999, // Adjust the z-index as needed
        }}
      >
        <img
          style={{
            width: '100%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={Tablet}
        />
      </div>
      <div style={{ position: 'relative', bottom: 30, right: 80 }}>
        <img
          style={{
            position: 'relative',
            bottom: 200,
            left: 100,
            zIndex: 999999,
            width: '30%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={TableSelectDetail}
        />
      </div>
      <div style={{ position: 'relative', bottom: 180, right: 120 }}>
        <img
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '95%',
            zIndex: 999999,
            width: '30%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={Checkout}
        />
      </div>
    </div>
    </Hidden>
    </>
  );
};


const SlideInComponent2 = () => {
  return (
    <>
    <Hidden lgDown>
    <div style={{ maxWidth: '100%', margin: '0 auto', position: 'relative' }}>
    <Box
      style={{ position: 'relative', top: 60, zIndex: 1, textAlign: 'center' }}
      className={`fade-in-text visible`}
      sx={{ mt: 4 }}
    >
      <p
        className="poppins-font"
        style={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 35 }}
      >        Customer gets notified via text message

      </p>
    </Box>
      <div style={{ position: 'relative', width: '100%', left: 200, bottom: 160}}>
        <img
          style={{
            paddingTop: 250,
            zIndex: 999999,
            width: '100%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={Sms}
        />
      </div>
    </div>
    </Hidden>
    <Hidden lgUp>
    <div style={{ maxWidth: '100%', margin: '0 auto', position: 'relative' }}>
    <Box
      style={{ position: 'relative', top: 60, zIndex: 1, textAlign: 'center' }}
      className={`fade-in-text visible`}
      sx={{ mt: 4 }}
    >
    <p
      className="poppins-font"
      style={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 18 }}
    >        Customer gets notified via text message

    </p>
    </Box>
      <div style={{ position: 'relative', width: '100%', left: 50, bottom: 140}}>
        <img
          style={{
            paddingTop: 250,
            zIndex: 999999,
            width: '100%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={Sms}
        />
      </div>
    </div>
    </Hidden>
    </>
  );
};

const SlideInComponent3 = () => {
  return (
    <div style={{ maxWidth: '100%', margin: '0 auto', position: 'relative' }}>
    <Box
      style={{ position: 'relative', top: 210, zIndex: 1, textAlign: 'center' }}
      className={`fade-in-text visible`}
      sx={{ mt: 4 }}
    >
      <Typography
        sx={{ color: 'rgba(255,255,255,0.69)', fontWeight: 300, fontSize: 35 }}
        variant="p"
        gutterBottom
      >
        Kitchen receives order through our KDS
      </Typography>
    </Box>
      <div style={{ position: 'relative', width: '100%', left: 200}}>
        <img
          style={{
            paddingTop: 250,
            zIndex: 999999,
            width: '100%',
            borderRadius: 12,
            opacity: 1,
          }}
          src={KitchenDisplay}
        />
      </div>
    </div>
  );
};


const Progress = (props) => {
  const containerStyle = {
    position: 'relative',
    bottom: 800,
    width: 20,
    height: 100,
    background: 'rgba(255,255,255, 0.3)',
    borderRadius: 30
  };

  const maxTravelDistance = 80; // 100 (container height) - 20 (ball height)

  // Calculate the top position for the ball based on the progress
  const currentTop = (props.progress / 10) * maxTravelDistance;

  const innerBallStyle = {
    position: 'absolute',
    top: currentTop,
    width: 20,
    height: 20,
    borderRadius: 100,
    background: 'gray'
  };

  return (
    <div style={containerStyle}>
      <div style={innerBallStyle}></div>
    </div>
  );
};



// ... [Your StepOne, StepTwo, StepThree components remain unchanged] ...
const Action = (props) => {
    const [activeStep, setActiveStep] = useState(0); // 0 for StepOne, 1 for StepTwo, and so on...
    const [hasSticked, setHasSticked] = useState(false);
    const [stopped, setstopped] = useState(true);

    const containerRef = useRef(null)
    const stepChangeIntervalRef = useRef(null);

    const stopStepChange = () => {
        if (stepChangeIntervalRef.current) {
            clearInterval(stepChangeIntervalRef.current);
            stepChangeIntervalRef.current = null;
            setstopped(true)
        }
    };


    const nextStep = () => {
        setActiveStep((prevStep) => (prevStep === 6 ? 0 : prevStep + 1));
    };

    const previousStep = () => {
        setActiveStep((prevStep) => (prevStep === 0 ? 6 : prevStep - 1));
    };

        useEffect(() => {

          const checkVisibilityAndScrollThreshold = () => {
            const rect = containerRef.current.getBoundingClientRect();

            if (rect.top <= 250 && !hasSticked) {
              setHasSticked(true);
            }

          }

          if (hasSticked) {
            setstopped(false)
                  stepChangeIntervalRef.current = setInterval(() => {
                      setActiveStep((prevStep) => (prevStep === 6 ? 0 : prevStep + 1));
                  }, 3500);
              }

            window.addEventListener('scroll', checkVisibilityAndScrollThreshold);
            return () => {
              if (stepChangeIntervalRef.current) {
                clearInterval(stepChangeIntervalRef.current);

              }
              stopStepChange();  // Clear the interval when the component is unmounted.
              setstopped(true)

              window.removeEventListener('scroll', checkVisibilityAndScrollThreshold);
            };
        }, [hasSticked]);


      const renderStep = (Component, index) => {
          let modifier = 0;
          if (activeStep === 3) {
              modifier = 1;
          } else if (activeStep === 4) {
             modifier = 2;
          }


          return (
            <div style={{
              position: index === 0 ? 'relative' : 'absolute',
              top: 0,
              left: 0,
              right: index === 0 ? 0 : 0,
              opacity: activeStep - modifier === index ? 1 : 0,
              visibility: activeStep - modifier === index ? 'visible' : 'hidden',
              pointerEvents: activeStep - modifier === index ? 'auto' : 'none',
            }}>
              <Component activeStep={activeStep} isVisible={hasSticked} hasSticked={hasSticked} />
            </div>
          );
      };


    return (
        <Grid
        ref={containerRef}

            style={{
                backgroundImage: 'linear-gradient(to right bottom, #1c125a, #1c1f5e, #202b62, #273563, #303f64, #303e60, #2f3d5c, #2f3c58, #24304f, #1a2445, #0f193c, #080b32)',
                padding: 20,
                paddingLeft: 0,
                paddingRight: 0,
                borderRadius: 30,
                marginTop: 50,
                overflow: 'hidden',
                position: 'relative',  // Setting the container as the reference for absolute positioning.
            }}
            justifyContent="center"
            direction="row"
            alignItems="flex-start"
            container
            spacing={0}
        >
        <br />
            <Grid item xs={12} sm={7} md={4} lg={5} style={{position: 'relative'}}>
            <ButtonGroup color="white" style={{position: 'absolute', left: '50%', top:'0%', transform: 'translateX(-50%)',size: 30,zIndex: 99999999999, background: 'rgba(255,255,255,0.05)', color: 'rgba(255,255,255,1)'}}
            >
            <IconButton htmlColor="white"  onClick={previousStep}><SkipPreviousIcon style={{color: 'white'}} /></IconButton>

            <IconButton onClick={stopStepChange}>{!stopped ? <PauseIcon  style={{color: 'white',  fontSize: 40}}  /> : <PlayArrowIcon  style={{color: 'white', fontSize: 40}}  />}</IconButton>
            <IconButton  onClick={nextStep}><SkipNextIcon  style={{color: 'white'}}  /></IconButton>

             </ButtonGroup>
            {renderStep(StepOne, 0)}
            {renderStep(StepTwo, 1)}
            {renderStep(StepThree, 2)}
            {renderStep(StepFour, 5)}
            {renderStep(StepFive, 6)}
            </Grid>
        </Grid>
    );
};

export default Action;
